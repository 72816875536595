import { Subject } from 'rxjs/Subject';
import { Observable } from 'rxjs/Observable';
import { Injectable } from '@angular/core';


export class VgControlsHidden {
    isHidden: Observable<boolean>;

    private isHiddenSubject: Subject<boolean> = new Subject<boolean>();

    constructor() {
        this.isHidden = this.isHiddenSubject.asObservable();
    }

    state(hidden: boolean) {
        this.isHiddenSubject.next(hidden);
    }
static decorators: DecoratorInvocation[] = [
{ type: Injectable },
];
/** @nocollapse */
static ctorParameters: ({type: any, decorators?: DecoratorInvocation[]}|null)[] = [
];
}

interface DecoratorInvocation {
  type: Function;
  args?: any[];
}
