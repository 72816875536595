import {Injectable} from '@angular/core';


export class VgStates {
    static VG_ENDED: string = 'ended';
    static VG_PAUSED: string = 'paused';
    static VG_PLAYING: string = 'playing';
    static VG_LOADING: string = 'waiting';
static decorators: DecoratorInvocation[] = [
{ type: Injectable },
];
/** @nocollapse */
static ctorParameters: ({type: any, decorators?: DecoratorInvocation[]}|null)[] = [
];
}

interface DecoratorInvocation {
  type: Function;
  args?: any[];
}
