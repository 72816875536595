import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { VgBuffering } from './vg-buffering';


export class VgBufferingModule {
static decorators: DecoratorInvocation[] = [
{ type: NgModule, args: [{
    imports: [ CommonModule ],
    declarations: [
        VgBuffering
    ],
    exports: [
        VgBuffering
    ]
}, ] },
];
/** @nocollapse */
static ctorParameters: ({type: any, decorators?: DecoratorInvocation[]}|null)[] = [
];
}

interface DecoratorInvocation {
  type: Function;
  args?: any[];
}
